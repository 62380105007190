<script>
export default {
  name: "Collapse",
  props: {
    label: {},
    defaultVisibility: { default: false },
    onDelete: { default: false },
    scrollTo: {},
    showUrl: {}
  },
  methods: {
    toggleVisibilityAndScroll() {
      this.visible = !this.visible;
      this.scrollTo.scrollIntoView()
    }
  },
  data() {
    return {
      visible: this.defaultVisibility
    }
  }
}
</script>

<template>
  <template v-if="visible">
    <div class="fields-box mt-3">
      <slot></slot>
      <hr/>
      <button class="btn btn-lg btn-primary" v-on:click="toggleVisibilityAndScroll">
        {{ $t('helpers.links.save') }}
      </button>
    </div>
  </template>
  <template v-else>
    <div class="row bg-light mx-0 mt-3 border rounded py-1 d-flex align-items-center" >
      <div class="col-8 col-md-9 d-flex align-items-center px-2">
        <slot name="labelBefore" />
        <span class="fw-bold ff-secondary ps-4">{{ label }}</span>
      </div>

      <div class="col-4 col-md-3 text-end">
        <div class="d-inline-block">
          <div class="d-flex gap-2 align-items-center justify-content-end">
            <button class="btn btn-sm btn-link text-dark fs-4 p-0" type="button" v-on:click="visible = !visible">
              <inline-svg :src="asset_path('theme/pencil.svg')"/>
            </button>

            <a v-if="showUrl" class="btn btn-sm btn-link text-dark fs-4 p-0" type="button" :href="showUrl" target="_blank">
              <inline-svg :src="asset_path('theme/eye.svg')"/>
            </a>

            <button v-if="onDelete" class="btn btn-sm btn-link text-dark fs-4 p-0" type="button" v-on:click="onDelete">
              <inline-svg :src="asset_path('theme/Trash.svg')"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
